import { type AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";

import { reasons, type TReasonAction } from "../../utils/redirectionReason";
import { addNotification } from "../slices/notification";
import type { TNotificationMessage } from "../slices/types/notification.types";
import type { TRedirectState } from "../slices/types/redirection.types";
import type { ThunkAPIConfig } from "../store";

/**
 * Dispatch action to push redirection state to the store.
 * Additional dispatch a notification-action.
 *
 * This Action is for redirect and 'massage stay on screen' when redirect within redux.
 *
 * @args {TReasonKeys} reason Key of the notification reason to select message and route
 * @args {string} route Optional argument to manipulate the to-redirect route. Works only with certain routes. ["no_data", "insufficient_funds"]
 */
export const doRedirect: AsyncThunk<TRedirectState, TReasonAction, ThunkAPIConfig> = createAsyncThunk<
    TRedirectState,
    TReasonAction,
    ThunkAPIConfig
>("auth/doRedirect", async (args, apiThunk) => {
    const { reason, route } = args;
    const dispatch = apiThunk.dispatch;

    if (reasons(route as string)[reason].notification)
        dispatch(addNotification(reasons(route as string)[reason].notification as TNotificationMessage));

    return reasons(route as string)[reason].redirect;
});

const redirectActionService = {
    doRedirect,
};

export default redirectActionService;

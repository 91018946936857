/* eslint-disable no-console */
import { AxiosError } from "axios";

import { axios } from "../../lib/axios";

/** Fetch helper for axios-get calls */
export const fetcher = async <ResponseType = unknown>(url: string, params?: object): Promise<ResponseType | null> =>
    axios
        .get<ResponseType>(url, { params })
        .then((res) => res.data)
        .catch((err: AxiosError) => {
            console.error(err);
            return null;
        });
